<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        
        <div>
            <tabsComp :tabs="tabs" />
            
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-10px !important;`">
                <v-row class="mt-10">
                    <v-col
                    cols="12"
                    md="10"
                    sm="12">
                    <form autocomplete="off">
                    <v-row>
                        <v-col cols="12" md="2" sm="12">
                        <label>{{lang.select_store}}</label>
                        <b-input-group>
                            <b-form-select
                            class="selborder"
                            v-model="store_id"
                            :options="storesVals"
                            ></b-form-select>
                            <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                        <label></label>
                        <b-input-group>
                            <b-button variant="light" style="padding-top:2px !important; padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getReport()">{{lang.search}}</b-button>
                        </b-input-group>
                        </v-col>
                    </v-row>
                    <b-input-group class="mt-3" style="display:none;">
                        <b-form-input placeholder="Start Date" onfocus="(this.type='date')" style="direction:rtl;border:1px solid #000;border-radius: 0;" type="date" v-model="sdate" :title="lang.start_date"></b-form-input>
                        <b-form-input placeholder="End Date" onfocus="(this.type='date')" style="border:1px solid #000" type="date" v-model="edate" :title="lang.end_date"></b-form-input>
                        <b-form-input :placeholder="lang.mobile"  style="border:1px solid #000" v-model="mobile"></b-form-input>
                        <b-form-input :placeholder="lang.plate_number" style="border:1px solid #000" v-model="plate_number"></b-form-input>
                        <b-input-group-append style="border:1px solid #000">
                        <!-- <b-button variant="light" style="background:gold;"  @click="getCurrentCards()"><i class="fas fa-search"></i></b-button> -->
                        <b-button variant="light" style="background:black;color:#fff"  @click="getCurrentCards()">{{lang.search}}</b-button>
                        </b-input-group-append>
                        
                    </b-input-group>
                    </form>
                    </v-col>
                    <v-col
                    cols="12"
                    md="100"
                    style="margin-bottom:80px;"
                    sm="12">
                    <v-data-table
                        :headers="headers"
                        :items="tbRows"
                        hide-default-footer
                        disable-pagination
                    >
                        <template v-slot:header="" >
                            <thead>
                                <tr>
                                    <th class="text-center" colspan="10" style="font-weight:bold;font-size:1rem;">{{ lang.inventory_report }}</th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="row">
                            <tr>
                                <td class="text-center" style="width:70px">{{ row.item.item_code }}</td>
                                <td class="text-center" style="">{{ row.item[`item_name${lang.langname}`] }}</td>
                                <td class="text-center" style="width:70px">{{ $RoundNum(row.item.costs,2) }}</td>
                                <td class="text-center" style="width:70px">{{ $RoundNum(row.item.qtty,2) }}</td>
                                <td class="text-center" style="width:70px">{{ $RoundNum(parseFloat(row.item.qtty) * parseFloat(row.item.costs),2) }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                    </v-col>
                </v-row>
            </v-row>
        </div>
        <Footer />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue';
import ExportsPrint from '@/components/exports-print.vue';
import Footer from '@/components/Footer.vue';
import tabsComp from '@/components/tabsComp.vue'
import axios from 'axios'
export default {
    components: {
        ExportsPrint, Footer, tabsComp, HeaderPortrate
    },
    data() {
        return {
            tbRows: [],
            store_id: 0,
            stores: [],
        }
    },
    computed: {
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },
      storesVals: function(){
        let t =[];
        for(let i=0;i<this.stores.length;i++){
            t.push({
                text: this.stores[i][`name_${this.lang.langname}`],
                value: this.stores[i].id
            })
        }
        return t;
      },
      headers: function(){
        return [
            {
                text: this.lang.item_code,
                align: 'center',
                sortable: false,
                value: 'item_number',
                class:"backBlack",
                printRemove: 0
            },
            {
                text: this.lang.item_name,
                align: 'center',
                sortable: false,
                value: 'item_name',
                class:"backBlack",
                printRemove: 0,
            },
            {
                text: this.lang.cost_price,
                align: 'center',
                sortable: false,
                value: 'cost',
                class:"backBlack",
                printRemove: 0,
            },
            {
                text: this.lang.qtty,
                align: 'center',
                sortable: false,
                value: 'parent_class_name',
                class:"backBlack",
                printRemove: 0,
            },
            {
                text: this.lang.total,
                align: 'center',
                sortable: false,
                value: 'type',
                class:"backBlack",
                printRemove: 0,
            },
            
        ]
      },
      tabs: function()
      {
        const menu = this.$store.getters['state'].menu;
        const license = this.$store.getters['state'].licenseType.activemenu;
        const alicense = this.$store.getters['state'].licenseType
        let currentMenu = menu[license];
        
        if(!license){
            return false;
        }
        let cMenu = {main: {},sub:[]};
        Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            // console.log("cM",cM);
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
        });
        
        let t = []
        // console.log("cMenu",cMenu);
        Object.values(cMenu).forEach(value =>{
        
            if(this.$route.path == value.href){
                value.class = "mytab";
            }else{
                value.class = "mytab4";
            }
            if(this.lang.langname == 'ar'){
                value.name = value.arname
            }else{
                value.name = value.enname
            }
            
            for(let i =0; i< value.groupid.length;i++){
                if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                }
            }
        })
    
        return t;
      }
    },
    methods:{
        getStores() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getStores");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',-1);
            let myar = [];
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.stores = res.results.data;
            })
        },
        getReport(){
            const post = new FormData();
            post.append('type','getInventoryReport');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[store_id]',this.store_id);
            
            this.$store.state.showLoading = true
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                this.$store.state.showLoading = false
                const res = response.data;
                

                this.tbRows = res.results.data;
            });
        }
    },
    created(){
        this.getStores()
    }
}
</script>
